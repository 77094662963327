export const locale = {
    lang: 'es',
    data: {
        NAV: {
            APPLICATIONS: 'Aplicaciones',
            DASHBOARDS  : 'Dashboards',
            CALENDAR    : 'Calendario',
            ECOMMERCE   : 'Tienda En Linea',
            ACADEMY     : 'Academia',
            MAIL        : {
                TITLE: 'Correo',
                BADGE: '25'
            },
            MAIL_NGRX        : {
                TITLE: 'Correo Ngrx',
                BADGE: '13'
            },
            CHAT        : 'Chat',
            FILE_MANAGER: 'File Manager',
            CONTACTS    : 'Contactos',
            TODO        : 'To-Do',
            SCRUMBOARD  : 'Scrumboard'
        }
    }
};
