import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'usuarios',
                title: 'Usuarios',
                type: 'item',
                icon: 'account_box',
                url: '/pages/usuarios'
            },
            {
                id: 'roles',
                title: 'Roles',
                type: 'item',
                icon: 'settings',
                url: '/pages/roles'
            },
            {
                id: 'clientes',
                title: 'Clientes',
                type: 'item',
                icon: 'people',
                url: '/pages/clientes'
            },
            {
                id: 'servicios',
                title: 'Servicios',
                type: 'item',
                icon: 'local_hospital',
                url: '/pages/servicios'
            },
            {
                id: 'proveedores',
                title: 'Proveedores',
                type: 'item',
                icon: 'how_to_reg',
                url: '/pages/proveedores'
            },
            {
                id: 'productos',
                title: 'Productos',
                type: 'item',
                icon: 'add_shopping_cart',
                url: '/pages/productos'
            },
            {
                id: 'casas',
                title: 'Casas Farmaceuticas',
                type: 'item',
                icon: 'home',
                url: '/pages/casas'
            },
            {
                id: 'lineas',
                title: 'Linea de Productos',
                type: 'item',
                icon: 'home',
                url: '/pages/lineas'
            },
            {
                id: 'presentaciones',
                title: 'Presentaciones',
                type: 'item',
                icon: 'library_add',
                url: '/pages/presentaciones'
            },
            {
                id: 'reportes',
                title: 'Reportes',
                type: 'item',
                icon: 'library_add',
                url: 'pages/reportes'
            }
        ]
    },

    {
        id: 'farmacia',
        title: 'Farmacia',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'caja',
                title: 'Caja',
                type: 'item',
                icon: 'credit_card',
                url: '/pages/facturas/4'
            }
           
        ]
    },
    {
        id: 'inventario',
        title: 'Inventario',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'bodega-central',
                title: 'Bodega - Compras',
                type: 'item',
                icon: 'assignment_returned',
                url: '/pages/compras'
            },
            {
                id: 'kardex',
                title: 'Kardex',
                type: 'item',
                icon: 'ballot',
                url: '/pages/kardex'
            },
        ]
    },
   
 
];
