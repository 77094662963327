import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../interfaces/usuario';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { isNullOrUndefined } from 'util';

import { navigation } from 'app/navigation/navigation';
import { navigationFarmacia } from 'app/navigation/navigation-farmacia';
import { navigationConsulta } from 'app/navigation/navigation-consulta';


const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  token: string = null;

  public usuario: Usuario = {};

  constructor(private http: HttpClient, private _fuseNavigationService: FuseNavigationService, private router: Router) { }

  login(email: string, password: string): Promise<boolean> {
    const data = { email, password };
    return new Promise(resolve => {
      this.http.post(`${URL}/user/login`, data).subscribe(async resp => {
        if (resp['ok']) {
          await this.guardarToken(resp['token']);
          // Obtener perfil de usuario
          resolve(true);
        } else {
          this.token = null;
          // localStorage.clear();
          localStorage.clear();
          resolve(false);
        }
      });
    });
  }


  cargarMenu(): void {
    // Obtener perfil de usuario
    switch (this.usuario.tipo) {
      // Asignar menú segun tipo
      case '5d6f2c3eaec9ea59929f7cb4':
        this._fuseNavigationService.register('admin-nav', navigation);
        this._fuseNavigationService.setCurrentNavigation('admin-nav');
        break;
      case '5ddf8d1b812ef914f5c2ed57':
        this._fuseNavigationService.register('farmacia-nav', navigationFarmacia);
        this._fuseNavigationService.setCurrentNavigation('farmacia-nav');
        break;
      case '606615943b6b5422148af0d8':
        this._fuseNavigationService.register('consulta-nav', navigationConsulta);
        this._fuseNavigationService.setCurrentNavigation('consulta-nav');
        break;
      default:
        this._fuseNavigationService.register('consulta-nav', navigationConsulta);
        this._fuseNavigationService.setCurrentNavigation('consulta-nav');
        break;
    }
  }


  logout(): void {
    this.token = null;
    switch (this.usuario.tipo) {
      // Asignar menú segun tipo
      case '5d6f2c3eaec9ea59929f7cb4':
        this._fuseNavigationService.unregister('admin-nav');
        break;
      case '5ddf8d1b812ef914f5c2ed57':
        this._fuseNavigationService.unregister('farmacia-nav');
        break;
      case '606615943b6b5422148af0d8':
          this._fuseNavigationService.unregister('consulta-nav');
          break;
      default:
        this._fuseNavigationService.unregister('consulta-nav');
        break;
    }
    // agregar API para desloguear
    const access_token = localStorage.getItem('token');
    const url_api = `${URL}/user/logout/${access_token}`;

    this.http.get(url_api)
      .subscribe(resp => {
        if (resp['ok']) {
          this.usuario = null;
          localStorage.clear();
          this.router.navigateByUrl('pages/auth/login-2');

        }
      });
  }


  getUsuario(): Usuario {
    if (!isNullOrUndefined(this.usuario._id)) {
      this.validaToken();
    }
    return { ...this.usuario };
  }

  async guardarToken(token: string): Promise<any> {
    this.token = token;
    await localStorage.setItem('token', JSON.stringify(token));
    await this.validaToken();
  }

  async cargarToken(): Promise<any> { // second step
    const tokenString = await localStorage.getItem('token') || null;
    if (!isNullOrUndefined(tokenString)) {
      // remueve el caracter " inicial y final que coloca el stringnify al guardar en localStorage
      this.token = tokenString.substring(1, tokenString.length - 1);
    }
    return this.token;
  }

  async validaToken(): Promise<boolean> { // first step
    await this.cargarToken();
    if (!this.token) {
      this.router.navigateByUrl('pages/auth/login-2');
      return Promise.resolve(false);
    } else {
      return new Promise<boolean>(resolve => {
        const headers = new HttpHeaders({
          'x-token': this.token
        });
        this.http.get(`${URL}/user/`, { headers })
          .subscribe(resp => {
            if (resp['ok']) {
              this.usuario = resp['usuario'];
              // this.router.navigateByUrl('/pages/inicio');
              this.router.navigateByUrl('/pages/facturas/nueva/1');
              this.cargarMenu();
              resolve(true);
            } else {
              this.router.navigateByUrl('pages/auth/login-2');
              resolve(false);
            }
          });
      });
    }
  }


  async verificarLogin(): Promise<boolean> { // first step
    await this.cargarToken();
    if (!this.token) {
      return false;
    } else {
      return true;
    }
  }


}
