import { FuseNavigation } from "@fuse/types";

export const navigationFarmacia: FuseNavigation[] = [
  {
    id: "farmacia",
    title: "Farmacia",
    type: "group",
    icon: "pages",
    children: [
      // {
      //     id: 'calendario',
      //     title: 'Calendario',
      //     type: 'item',
      //     icon: 'date_range',
      //     url: '/pages/calendar/3'
      // },
      {
        id: "recepcion",
        title: "Facturas",
        type: "item",
        icon: "person_add",
        url: "/pages/facturas/3", // nuevo, paciente pide e ingresa a factura
      },
      {
        id: "productos",
        title: "Productos",
        type: "item",
        icon: "add_shopping_cart",
        url: "/pages/productos",
      },

      {
        id: "caja",
        title: "Caja",
        type: "item",
        icon: "credit_card",
        url: "/pages/facturas/4",
      },
      {
        id: "kardex",
        title: "Kardex",
        type: "item",
        icon: "ballot",
        url: "/pages/kardex",
      },
      {
        id: "reportes",
        title: "Reportes",
        type: "item",
        icon: "library_add",
        url: "pages/reportes",
      },
    ],
  },
];
