import { FuseNavigation } from '@fuse/types';

export const navigationConsulta: FuseNavigation[] = [
    {
        id: 'consulta',
        title: 'Consulta',
        type: 'group',
        icon: 'pages',
        children: [
            {
                id: 'productos',
                title: 'Productos',
                type: 'item',
                icon: 'add_shopping_cart',
                url: '/pages/productos'
            },
            {
                id: 'kardex',
                title: 'Kardex',
                type: 'item',
                icon: 'ballot',
                url: '/pages/kardex'
            },
        ]
    }
];
